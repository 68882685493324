import React, {useState} from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';
import {BiMenu} from "react-icons/bi";
import {IoIosArrowForward} from "react-icons/io";

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: ${({open}) => open ? 'fixed' : 'absolute'};
  top: ${({open}) => open ? '16px' : '4px'};
  right: 20px;
  z-index: ${({open}) => open ? '99999' : '500'};
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({open}) => open ? '#ccc' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({open}) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({open}) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;
const MenuShadow = styled.div`
            background-color: #444444;
            opacity: 0.6;
            z-index: 99;
            width: 95vw;
            height: 100vh;
            top: -10px;
            position: ${({open}) => open ? 'absolute' : 'relative'};
            display: ${({open}) => open ? 'block' : 'none'};
`;

const Burger = ({logo}) => {
    const [open, setOpen] = useState(false)
    const closeMenu = () => {
        setOpen(!open)
    }
    return (
        <>
            <StyledBurger className="menu-icon" open={open} onClick={() => setOpen(!open)}>
                {
                    open === true ? (<span className='back-menu-icon '><IoIosArrowForward className='m-auto d-flex justify-content-center'  size={'40px'}/></span>) : (
                        <>
                            <span className='menu'> <BiMenu className='m-auto d-flex justify-content-center' color={'#666666'} size={'30px'}/></span>
                        </>)
                }
            </StyledBurger>
            <RightNav
                open={open}
                logo={logo}
                toggle={() => closeMenu()}
            />
            <MenuShadow open={open} onClick={closeMenu}/>
        </>
    )
}

export default Burger
