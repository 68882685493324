import React from "react";
import {Phone} from "react-feather";
import {Col, Row} from "reactstrap";
import logo from "../../../assets/img/logo/logo1.webp";
import {FaInstagram} from "react-icons/fa";
import {FaTelegramPlane} from "react-icons/fa";
import {FiYoutube} from "react-icons/fi";
import zarin from "../../../assets/img/elements/zarin.png"
import {MdOutlineWhatsapp} from "react-icons/md";


class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            whatsapp: false,
            enamad: true,
        }
    }

    support = () => {
        this.setState({
            whatsapp: !this.state.whatsapp,
        })
    }

    render() {
        return (
            <>
                <footer className="web-footer">
                    <div className="bg-black-footer ">
                        <Row>
                            <Col md={5} sm={12}>
                                <div className='footer-logo'>
                                    <img src={logo} alt='logo'/>
                                    <div className="h6">آموزشگاه حرفه‌ای و زیبایی بیتا بهمن زاده</div>
                                </div>
                                <div className='footer-desc'>
                                    <p>
                                        <a href="/about-us"><strong>آموزشگاه حرفه‌ای و زیبایی بیتا بهمن زاده </strong></a>، مرکزی برای آموزش و ارائه
                                        خدمات زیبایی مانند <a
                                        href="/courses/1402-8-28-آموزش-تزریق-بوتاکس"><strong>بوتاکس</strong></a> و <a
                                        href="/courses/1402-8-28-آموزش-لیفت-نخ-صورت"><strong> لیفت
                                        صورت</strong></a> است. ما با تیم متخصص و حرفه‌ای خود به ارائه خدمات برتر و
                                        جدیدترین تکنیک‌ها
                                        در زمینه زیبایی و آرایش می‌پردازیم. از ما برای بهبود و زیبایی صورت خود اطمینان
                                        حاصل کنید. با استفاده از محصولات و فناوری‌های مدرن، به شما نتایجی طبیعی و دلخواه
                                        خواهیم داد.
                                    </p>
                                </div>
                                <div className='footer-social'>
                                    <a href="https://www.instagram.com/Bitabahmanzadeh_amozesh/" aria-label="https://www.instagram.com/Bitabahmanzadeh_amozesh/">
                                        <span> <FaInstagram/></span>
                                    </a>
                                    <a href="https://t.me/bitabahmanzade" aria-label="https://t.me/bitabahmanzade">
                                        <span><FaTelegramPlane/></span>
                                    </a>
                                    <a href="https://youtube.com/Bitabahmanzade" aria-label="https://youtube.com/Bitabahmanzade">
                                        <span><FiYoutube/></span>
                                    </a>
                                    <a href="https://api.whatsapp.com/send/?phone=9126585894&text&app_absent=0" aria-label="https://api.whatsapp.com/send/?phone=9126585894&text&app_absent=0">
                                        <span><MdOutlineWhatsapp/></span>
                                    </a>
                                </div>
                            </Col>
                            <Col md={7} sm={12}>
                                <Row>
                                    <Col md={4} sm={12}>
                                        <span className='title-footer h5'>لینک دسترسی سریع</span>
                                        <Row className='footer-speed-container'>
                                            <Col md={6} sm={12} className='footer-link-speeed'>
                                                <a href="/contact-us"> تماس با ما</a>
                                            </Col>
                                            <Col md={6} sm={12} className='footer-link-speeed'>
                                                <a href="/about-us">درباره ما</a>
                                            </Col>
                                            <Col md={6} sm={12} className='footer-link-speeed'>
                                                <a href="/articles">مقالات</a>
                                            </Col>
                                            <Col md={6} sm={12} className='footer-link-speeed'>
                                                <a href="/gallery">گالری</a>
                                            </Col>
                                            <Col md={6} sm={12} className='footer-link-speeed'>
                                                <a href="/courses/1402-8-28-آموزش-تزریق-بوتاکس">تزریق بوتاکس</a>
                                            </Col>
                                            <Col md={6} sm={12} className='footer-link-speeed'>
                                                <a href="/courses/1402-8-28-آموزش-لیفت-نخ-صورت">لیفت صورت</a>
                                            </Col>
                                            <Col md={6} sm={12} className='footer-link-speeed'>
                                                <a href="/courses/1402-8-28-آموزش-مزوتراپی">مزوتراپی</a>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <span className='title-footer h5'>تماس با ما</span>

                                        <div className='footer-location'>
                                            <span>آدرس: </span>
                                            <p>شهرک راه آهن بلوار امیر کبیر</p>
                                        </div>

                                        <div className='footer-phone'>
                                            <span>تلفن: </span>
                                            <a href="tel:+989126585894">
                                                <Phone/> 09126585894
                                            </a>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <div className='namad'>
                                            <div className='namad-container'>
                                                <img src={zarin} alt="زرین پال" className='w-75'/>
                                            </div>
                                            <div className='namad-container mt-namad'>
                                                <a referrerPolicy='origin' target='_blank' aria-label="اینماد" title="اینماد"
                                                   href='https://trustseal.enamad.ir/?id=452929&Code=COCDEKyAC2uAex5xK0yKFfOH5tnVNqas'><img
                                                    className='w-75'
                                                    referrerPolicy='origin'
                                                    src='https://trustseal.enamad.ir/logo.aspx?id=452929&Code=COCDEKyAC2uAex5xK0yKFfOH5tnVNqas'
                                                    alt='اینماد' style={{cursor: 'pointer'}}
                                                    Code='COCDEKyAC2uAex5xK0yKFfOH5tnVNqas'/></a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>

                    <div className='border-footer'></div>

                    <div className="copyright p-1 w-100 m-auto">
                        <p className="m-0">
                            {'کلیه حق و حقوق وبسایت برای '}
                            <a href="https://bitabahmanzadeh.ir">
                                <strong className='text-bold'>
                                    آکادمی بیتا بهمن زاده
                                </strong>
                            </a>
                            {' محفوظ است. '}
                        </p>
                        <a href="https://barantm.ir" target='_blank' rel='noopener' className="my-auto">
                            <p className="m-0">
                                {' این وبسایت توسط '}
                                <a href="https://barantm.com">
                                    <strong className='text-bold'>
                                        شرکت برنامه نویسی باران
                                    </strong>
                                </a>
                                {'  طراحی و تولید شده است.'}
                            </p>
                        </a>

                    </div>

                </footer>

                <div className="copyright copyright-phone p-1 w-100 m-auto">
                    <p className="m-0">
                        کلیه حق و حقوق وبسایت برای آکادمی بیتا بهمن زاده محفوظ است
                    </p>
                </div>

            </>
        );
    }
}

export default Footer;
